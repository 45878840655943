<template>
  <div class="reportBox">
    <div class="reportTitle">儿童成长发育报告</div>
    <div class="time">
      <span>报告时间：</span>
      <span class="lineTextBox">{{
        info.createTime.replace("T", " ").slice(0, 10)
      }}</span>
    </div>
    <div class="contain">
      <h2>个人信息</h2>
      <div class="content">
        <div class="infoLine">
          <span>姓名：</span>
          <span class="lineTextBox">{{ info.nickName }}</span>
          <span>性别：</span>
          <span class="lineTextBox">{{ info.sex ? "女" : "男" }}</span>
          <span>年龄：</span>
          <span class="lineTextBox">{{ info.nl }}</span>
        </div>
        <div class="infoLine">
          <span>出生日期：</span>
          <span class="lineTextBox">{{ info.age }}</span>
        </div>
        <div class="infoLine">
          <span>父亲身高：</span>
          <span class="lineTextBox">{{ info.fatherHeight }}cm</span>
          <span>母亲身高：</span>
          <span class="lineTextBox">{{ info.motherHeight }}cm</span>
        </div>
        <div class="infoLine">
          <span>遗传成年身高：</span>
          <span class="lineTextBox">{{ info.yichuanHeight }}cm ± 5cm</span>
        </div>
        <div class="infoLine">
          <span>地址：</span>
          <span class="lineTextBox"
            >{{ info.province }}{{ info.city }}{{ info.area }}{{ info.school
            }}{{ info.classLevel }}{{ info.classField }}</span
          >
        </div>
      </div>
    </div>
    <div class="contain">
      <h2>体格检查</h2>
      <div class="content">
        <div class="infoLine">
          <span>身高：</span>
          <span class="lineTextBox">{{ info.height }}cm</span>
          <span>体重：</span>
          <span class="lineTextBox">{{ info.weight }}kg</span>
        </div>
        <div class="infoLine">
          <span>BMI指数：</span>
          <span class="lineTextBox" v-if="info.bmi < 18.5">偏瘦({{info.bmi}})</span>
          <span class="lineTextBox" v-else-if="info.bmi < 24.9 && info.bmi >= 18.5">正常({{info.bmi}})</span>
          <span class="lineTextBox" v-else-if="info.bmi < 29.9 && info.bmi >= 24.9">超重({{info.bmi}})</span>
          <span class="lineTextBox" v-else>肥胖({{info.bmi}})</span>
        </div>
        <div class="time">
          <span>医生：</span>
          <span class="lineTextBox" style="min-width: 80px"> </span>
        </div>
      </div>
    </div>
    <div class="contain">
      <h2>足踝检查</h2>
      <div class="content">
        <div class="checkBox">
          <div class="check" v-for="item in dataList" :key="item.name">
            <img
              v-if="item.checked"
              src="@/assets/images/checked.png"
              style="width: 20px"
              alt=""
            />
            <img
              v-else
              src="@/assets/images/check.png"
              style="width: 20px"
              alt=""
            />
            <span>{{ item.name }}</span>
          </div>
        </div>
        <div class="time">
          <span>医生：</span>
          <span class="lineTextBox" style="min-width: 80px"> </span>
        </div>
      </div>
    </div>
    <div class="contain">
      <h2>体态检查</h2>
      <div class="content">
        <div class="checkBox">
          脊柱侧弯：
          <div class="check" style="width: auto; min-width: 0">
            <img
              v-if="info.jiZhuCeWan === '无异常'"
              src="@/assets/images/checked.png"
              style="width: 20px"
              alt=""
            />
            <img
              v-else
              src="@/assets/images/check.png"
              style="width: 20px"
              alt=""
            />
            <span>无异常</span>
          </div>
          <div class="check" style="width: auto; min-width: 0">
            <img
              v-if="info.jiZhuCeWan === '轻度'"
              src="@/assets/images/checked.png"
              style="width: 20px"
              alt=""
            />
            <img
              v-else
              src="@/assets/images/check.png"
              style="width: 20px"
              alt=""
            />
            <span>轻度</span>
          </div>
          <div class="check" style="width: auto; min-width: 0">
            <img
              v-if="info.jiZhuCeWan === '中度'"
              src="@/assets/images/checked.png"
              style="width: 20px"
              alt=""
            />
            <img
              v-else
              src="@/assets/images/check.png"
              style="width: 20px"
              alt=""
            />
            <span>中度</span>
          </div>
          <div class="check" style="width: auto; min-width: 0">
            <img
              v-if="info.jiZhuCeWan === '重度'"
              src="@/assets/images/checked.png"
              style="width: 20px"
              alt=""
            />
            <img
              v-else
              src="@/assets/images/check.png"
              style="width: 20px"
              alt=""
            />
            <span>重度</span>
          </div>
          <div class="check" style="width: auto; min-width: 0">
            <img
              v-if="info.jiZhuCeWan === '极重度'"
              src="@/assets/images/checked.png"
              style="width: 20px"
              alt=""
            />
            <img
              v-else
              src="@/assets/images/check.png"
              style="width: 20px"
              alt=""
            />
            <span>极重度</span>
          </div>
        </div>
        <div class="checkBox">
          其他体态：
          <div class="check" style="width: auto; min-width: 0">
            <img
              v-if="info.isGaoDiJian !== '无'"
              src="@/assets/images/checked.png"
              style="width: 20px"
              alt=""
            />
            <img
              v-else
              src="@/assets/images/check.png"
              style="width: 20px"
              alt=""
            />
            <span>高低肩</span>
          </div>
          <div class="check" style="width: auto; min-width: 0">
            <img
              v-if="info.isGuPenQianQing !== '无'"
              src="@/assets/images/checked.png"
              style="width: 20px"
              alt=""
            />
            <img
              v-else
              src="@/assets/images/check.png"
              style="width: 20px"
              alt=""
            />
            <span>骨盆前倾</span>
          </div>
          <div class="check" style="width: auto; min-width: 0">
            <img
              v-if="info.isQiTaZhengZhuang !== '无'"
              src="@/assets/images/checked.png"
              style="width: 20px"
              alt=""
            />
            <img
              v-else
              src="@/assets/images/check.png"
              style="width: 20px"
              alt=""
            />
            <span>其他症状</span>
          </div>
          <div class="check" style="width: auto; min-width: 0">
            <img
              v-if="info.isTiTaiOk !== '无'"
              src="@/assets/images/checked.png"
              style="width: 20px"
              alt=""
            />
            <img
              v-else
              src="@/assets/images/check.png"
              style="width: 20px"
              alt=""
            />
            <span>体态良好</span>
          </div>
        </div>
        <div class="time">
          <span>医生：</span>
          <span class="lineTextBox" style="min-width: 80px"> </span>
        </div>
      </div>
    </div>
    <div class="contain">
      <h2>口腔检查</h2>
      <div class="content">
        <div class="infoLine">
          <span>口腔内牙齿总数：</span>
          <span class="lineTextBox" style="min-width: 60px; flex: 0">{{
            info.teethCounts
          }}</span>
          <span>颗</span>
        </div>
        <div class="checkBox" style="margin-top: 20px">
          <div class="check" v-for="item in dataList2" :key="item.name">
            <img
              v-if="item.checked"
              src="@/assets/images/checked.png"
              style="width: 20px"
              alt=""
            />
            <img
              v-else
              src="@/assets/images/check.png"
              style="width: 20px"
              alt=""
            />
            <span>{{ item.name }}</span>
          </div>
        </div>
        <div class="time">
          <span>医生：</span>
          <span class="lineTextBox" style="min-width: 80px"> </span>
        </div>
      </div>
    </div>
    <div class="contain">
      <h2>视力检查</h2>
      <div class="content">
        <el-table class="table" :data="tableData">
          <el-table-column prop="name" label="" width="60" />
          <el-table-column prop="vision" align="center" label="裸眼视力" />
          <el-table-column prop="axisLength" align="center" label="眼轴长度（mm）" width="90"/>
          <el-table-column prop="diopter" align="center" label="屈光度（D）" width="80" />
        </el-table>
        <div class="time">
          <span>医生：</span>
          <span class="lineTextBox" style="min-width: 80px"> </span>
        </div>
      </div>
    </div>
    <div class="contain">
      <h2>结论</h2>
      <div class="content">
        <div class="textBox">
          <p>
            您孩子处于{{
              age.years | ageName(info.sex || "")
            }}，身体和大脑发育重要阶段和关键时期
          </p>
          <p>
            <span>身高{{ hInfo.hText || "" }}：</span>当前身高{{
              hInfo.myHeight || ""
            }}cm，比标准身高{{ hInfo.normalHeight }}cm
            {{ hInfo.isMore ? "高" : "矮" }}
            {{ hInfo.chaHeight || "" }}cm，年长高 {{ hInfo.yearHeight || "" }}cm
          </p>
          <p>
            <span>体重{{ wInfo.wText || "" }}：</span>当前体重{{
              wInfo.myWeight || ""
            }}kg，比标准体重{{ wInfo.normalWeight || "" }}kg{{
              wInfo.isMore ? "重" : "轻"
            }}
            {{ wInfo.chaHeight || "" }}kg， 年增重
            {{ wInfo.yearWeight || "" }}公斤
          </p>
        </div>
      </div>
    </div>
    <div class="contain">
      <h2>建议</h2>
      <div class="content">
        <div class="textBox">
          <div class="line" v-for="(item, index) in sugInfo.sug" :key="item">
            <em>{{ index + 1 }}、</em>
            <span>{{ item }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import suggest from "@/utils/suggest.js";
export default {
  name: "Login",
  data() {
    return {
      age: {},
      wInfo: {},
      hInfo: {},
      suggest,
      sugInfo: {},
      dataList: [
        {
          name: "扁平足",
          checked: false,
        },
        {
          name: "高足弓",
          checked: false,
        },
        {
          name: "足外翻",
          checked: false,
        },
        {
          name: "内外八",
          checked: false,
        },
        {
          name: "X型腿",
          checked: false,
        },
        {
          name: "O型腿",
          checked: false,
        },
        {
          name: "正常，足弓发育良好",
          checked: false,
        },
      ],
      dataList2: [
        //口腔检查
        {
          name: "龋齿",
          checked: false,
        },
        {
          name: "牙菌斑",
          checked: false,
        },
        {
          name: "乳牙滞留",
          checked: false,
        },
        {
          name: "牙列不齐",
          checked: false,
        },
        {
          name: "咬合不正",
          checked: false,
        },
        {
          name: "牙龈炎",
          checked: false,
        },
        {
          name: "无异常",
          checked: false,
        },
      ],
      tableData: [
        {
          name: "左眼",
          vision: "5.0",
          axisLength: "4.8",
          diopter: "11",
        },
        {
          name: "右眼",
          vision: "5.0",
          axisLength: "4.8",
          diopter: "11",
        },
      ],
      info: {
        conclusion: "", //结论
        suggest: "", //建议
        nl: "",
        createTime: "",
      },
    };
  },
  async mounted() {
    const { name, phone } = this.$route.query;
    const res = await this.getAjax(
      `api/wx/user/base/searchByPhoneAndName`,
      {
        name,
        phone,
      },
      "post"
    );
    this.info = res.data;
    const years =
      new Date().getFullYear() - new Date(this.info.age).getFullYear();
    this.info.nl = years;
    if (this.info.isBianPingZu === "有") {
      this.dataList[0].checked = true;
    }
    if (this.info.isGaoZuGong === "有") {
      this.dataList[1].checked = true;
    }
    if (this.info.isZuWaiFan === "有") {
      this.dataList[2].checked = true;
    }
    if (this.info.isNeiWaiBa === "有") {
      this.dataList[3].checked = true;
    }
    if (this.info.isXTui === "有") {
      this.dataList[4].checked = true;
    }
    if (this.info.isOTui === "有") {
      this.dataList[5].checked = true;
    }
    if (this.info.isZuHuaiOk === "有") {
      this.dataList[6].checked = true;
    }

    //分界

    if (this.info.isQuChi === "有") {
      this.dataList2[0].checked = true;
    }
    if (this.info.isYaJunBan === "有") {
      this.dataList2[1].checked = true;
    }
    if (this.info.isRuYaZhiLiu === "有") {
      this.dataList2[2].checked = true;
    }
    if (this.info.isYaLieBuQi === "有") {
      this.dataList2[3].checked = true;
    }
    if (this.info.isYaoHeBuZhen === "有") {
      this.dataList2[4].checked = true;
    }
    if (this.info.isYaYinYan === "有") {
      this.dataList2[5].checked = true;
    }

    const result = this.dataList2.every(e=>!e.checked);
    this.dataList2[6].checked = result;

    this.tableData[0].vision = parseFloat(this.info.leftNakedEyeVision).toFixed(1);
    this.tableData[0].diopter = parseFloat(this.info.leftDiopter).toFixed(1);
    this.tableData[0].axisLength = parseFloat(this.info.leftAxisLength).toFixed(1);
    this.tableData[1].vision = parseFloat(this.info.rightNakedEyeVision).toFixed(1);
    this.tableData[1].diopter = parseFloat(this.info.rightDiopter).toFixed(1);
    this.tableData[1].axisLength = parseFloat(this.info.rightAxisLength).toFixed(1);
    console.log(this.tableData);
    //建议
    this.age = this.calcAge(this.info.age);
    this.hInfo = this.calcHeight(
      this.age.years,
      this.age.months,
      this.info.sex,
      this.info.height
    );
    this.wInfo = this.calcWeight(
      this.age.years,
      this.age.months,
      this.info.sex,
      this.info.weight
    );
    this.sugInfo = this.suggest[this.hInfo.hNum + "-" + this.wInfo.wNum];
  },
  methods: {},
};
</script>

<style lang="less">
.reportBox {
  padding: 15px;
  .table {
    width: 100%;
  }
  .textBox {
    min-height: 80px;
    word-wrap: break-word;
    word-break: break-all;
  }
  .checkBox {
    display: flex;
    flex-wrap: wrap;
    line-clamp: 3;
    .check {
      display: flex;
      align-items: center;
      min-width: 33.33%;
      margin-bottom: 15px;
      span {
        margin-left: 3px;
      }
    }
  }
}
.reportTitle {
  color: #1fb6c1;
  font-size: 25px;
  text-align: center;
  padding: 10px 0 0;
  font-weight: bold;
}
.time {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.lineTextBox {
  display: inline-block;
  padding: 0 10px 2px;
  border-bottom: 1px solid #666;
}

.contain {
  margin-top: 15px;
  border: 1px solid #1fb6c1;
  border-radius: 10px;
  overflow: hidden;
}
.contain h2 {
  height: 40px;
  background: #1fb6c1;
  color: #fff;
  padding: 0 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
}
.contain .content {
  padding: 15px;
}

.infoLine {
  display: flex;
  margin-top: 10px;
}

.infoLine .lineTextBox {
  flex: 1;
  text-align: center;
}
</style>
