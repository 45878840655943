export default {
	'1-1': { //身高矮小、体重消瘦
		sug: [
			'及时到医院检查，确认身高矮小、体重消瘦是否由疾病引起。',
			'维护孩子的自尊心，避免就身高和体重问题进行负面讨论。',
			'多吃牛肉、虾、鱼、紫菜、蛋类、奶制品、坚果、燕麦、核桃、豌豆、扁豆等。重点补充蛋白质、氨基丁酸、维生素D、钙锌等。',
			'多做开合跳、高抬腿、前后跳等跳跃性运动，保证每天30分钟以上运动。保证每天8小时以上的充足睡眠。',
		],
		details: {
			"一、及时就医": [
				"安排到医院进行全面检查，确定身高矮小、体重消瘦是否由于生长激素缺乏、骨骼发育障碍等疾病导致，以便及早治疗",
				"进行骨龄评估，甲状腺功能检测，微量元素分析，肝功能检查，以及血常规检查，以全面了解健康状况",
				"定期跟踪监测孩子的身高、体重变化，以及整体健康状态"
			],
			"二、积极心态": [
				"尊重孩子的感受，避免就身高和体重问题进行负面评价，维护其自尊心",
				"鼓励孩子参与各种活动，发展个人兴趣和技能，提升自信心",
				"关注孩子的情绪波动和焦虑，注意是否有霸凌、学习压力、家庭问题等异常情况，主动沟通并提供心理支持"
			],
			"三、加强营养": [
				"了解孩子的消化吸收情况，建议进行食物不耐受、肠道微生态和幽门螺杆菌的相关检查",
				"以促进生长激素分泌和增加体重为目标，推荐食用富含蛋白质和能量的食物，如牛肉、虾、鱼、紫菜、蛋类、奶制品、坚果、燕麦、核桃、豌豆、扁豆等",
				"提供额外的营养补充，如蛋白质、氨基丁酸、维生素D、钙和锌，4岁以上可适当使用肠内营养补充剂",
				"鼓励多饮水，保持水分平衡，定时定量进餐，避免长时间空腹或暴饮暴食"
			],
			"四、运动锻炼": [
				"制定每日至少30分钟的运动计划，以刺激生长激素分泌和增加体重",
				"选择有利于生长的运动，如游泳、篮球、跳绳、瑜伽、普拉提、体操等，避免过度负重运动，如长跑、举重",
				"早晨起床后进行简单的伸展运动，增强肌肉和关节的柔韧性，如手臂上举、腿部伸展、躯干扭转等，每天坚持5分钟",
				"实施跳绳锻炼，每天4组，每组60秒，以及Tabata训练，包括垫脚尖跳、开合跳、挺身跳等，每动作30秒，每组4分钟，每天2-4组",
				"提倡参与户外运动，如骑自行车、健行、游泳、跆拳道等，以增加阳光照射和增强体质"
			],
			"五、生活习惯": [
				"限制孩子晚上使用电子设备，以保证良好的睡眠质量",
				"确保晚上10点前上床休息，确保每天睡眠时间不少于8小时",
				"关注孩子的睡眠状况，如入睡困难、易惊醒、流口水等问题，及时采取改善措施",
				"营造安全的室内环境，避免甲醛、VOC超标及噪声污染，确保孩子健康成长"
			]
		}
	},
	'1-2': {
		// 身高矮小、体重正常
		sug: [
			'及时到医院检查，确认身高矮小是否由疾病引起。',
			'维护孩子的自尊心，避免因身高矮小可能会产生的自卑感。',
			'多吃牛肉、虾、鱼、紫菜、蛋类、奶制品、坚果、燕麦、核桃、豌豆、扁豆等，避免吃过多的糖分和加工食品。',
			'多做跳跃和拉伸运动，如开合跳、高抬腿、前后跳等，每天30分钟以上运动时间。保证每天8小时以上的充足睡眠。',
		],
		details: {
			"一、及时就医": [
				"安排到医疗机构进行全面检查，以查明身高矮小是否源于生长激素缺乏、骨骼发育障碍等潜在健康问题",
				"包括进行骨龄检测，甲状腺功能评估，微量元素分析，肝功能检查，以及常规血液检查，全面了解孩子的健康状况",
				"定期进行身高、体重的监测，以及对整体健康状况的评估，以便及时发现和处理任何问题"
			],
			"二、积极心态": [
				"关注孩子的情感需求，避免就身高问题进行消极的讨论，维护其自尊心",
				"鼓励孩子参加各种活动，培养兴趣和技能，通过这些活动来提高自信心",
				"密切关注孩子的情绪变化，如焦虑、不安，关注是否有霸凌、学习压力、家庭问题等，积极与孩子沟通，提供心理支持"
			],
			"三、加强营养": [
				"了解孩子的消化吸收状况，建议进行食物不耐受、肠道微生态和幽门螺杆菌的检查",
				"为了刺激生长激素分泌和维持适宜体重，建议摄取牛肉、虾、鱼、紫菜、蛋类、奶制品、坚果、燕麦、核桃、豌豆、扁豆等富含蛋白质和钙质的食物，同时减少糖分和加工食品的摄入",
				"为孩子提供必要的营养补充，如蛋白质、氨基丁酸、维生素D、钙和锌，4岁以上的孩子可以考虑使用肠内营养补充剂",
				"鼓励孩子多喝水，保持水分平衡，遵循定时定量的饮食原则，减少高糖、高盐、高脂肪食品的摄入"
			],
			"四、运动锻炼": [
				"确保孩子每天至少进行30分钟的运动，以刺激生长激素分泌和保持体重",
				"推荐进行跳跃性、拉伸性运动，如游泳、篮球、跳绳、瑜伽、普拉提、体操等，避免过度负重的运动，如长距离跑步、举重",
				"早晨起床后，进行简单的伸展运动，如手臂上举、腿部伸展、躯干扭转等，每天坚持5分钟，以提高身体的柔韧性和唤醒肌肉",
				"进行跳绳训练，每天4组，每组60秒，同时尝试Tabata训练，包括垫脚尖跳、开合跳等，每动作30秒，每组4分钟，每天2-4组",
				"参与功能性训练，如跳跃、爬坡、深蹲、平板支撑等，以增强身体机能，同时进行循环训练，如慢跑、骑行，以锻炼腿部肌肉和提高心肺功能"
			],
			"五、生活习惯": [
				"限制孩子晚上使用电子设备的时间，以促进高质量的睡眠",
				"确保孩子在晚上10点前上床休息，保证每天至少8小时的睡眠",
				"关注孩子的睡眠质量，留意入睡困难、易惊醒、流口水等症状，适时进行调整",
				"创造安全的室内环境，避免甲醛、VOC超标以及噪声污染，以保障孩子的健康生活环境"
			]
		}
	},
	'1-3': {
		// 身高矮小、体重偏重
		sug: [
			'及时到医院检查，确认身高矮小、体重偏重是否由疾病引起。',
			'增强孩子自信心，避免因矮小、偏重可能会产生的自卑感。',
			'调理脾胃，多吃牛肉、虾、鱼、紫菜、蛋类、奶制品、坚果、燕麦、核桃、豌豆、扁豆等，限制高糖、高脂肪和高盐食物。',
			'多做低冲击性的运动，控制体重，每天30分钟以上运动时间。保证每天8小时以上的充足睡眠。',
		],
		details: {
			"一、及时就医": [
				"安排孩子到专业医疗机构进行全面检查，以确定身高矮小、体重偏重是否源于生长激素缺乏、骨骼发育障碍等疾病",
				"定期进行身高、体重、血压、血糖和胆固醇水平的检查，全面掌握孩子的健康状况"
			],
			"二、积极心态": [
				"理解和尊重孩子可能存在的自卑感，通过沟通建立自信心，维护孩子的自尊心",
				"教育孩子理解健康饮食和运动的价值，家庭成员共同参与健康活动，如户外徒步、骑自行车或共享健康膳食的制作"
			],
			"三、加强营养": [
				"了解孩子的消化吸收情况，建议进行食物不耐受、肠道微生态和幽门螺杆菌的检查",
				"为刺激生长激素分泌和保持健康体重，鼓励食用牛肉、虾、鱼、紫菜、蛋类、奶制品、坚果、燕麦、核桃、豌豆、扁豆等富含蛋白质和钙的食物，同时限制高糖、高脂肪、高盐食品",
				"提供必要的营养补充，如蛋白质、氨基丁酸、维生素D、钙锌，4岁以上的孩子可以适当使用肠内营养补充剂",
				"鼓励孩子多喝水，保持充足的水分摄入，养成定时定量的饮食习惯，减少吃零食和暴饮暴食的行为"
			],
			"四、运动锻炼": [
				"设定每周至少5天的中等强度运动计划，如快走、游泳、跳舞、乒乓球、羽毛球等，确保每天运动时间不少于30分钟",
				"推荐进行低冲击力的运动，如游泳、骑自行车、慢跑、体操、踢毽子、瑜伽等，并加入团队运动，如篮球、足球等",
				"早晨起床后，进行简单的伸展运动，如手臂上举伸展、腿部伸展、躯干扭转，每天坚持5分钟，提高身体柔韧性",
				"实施跳绳训练，每天4组，每组60秒，以及Tabata训练，包括垫脚尖跳、开合跳等，每动作30秒，每组4分钟，每天2-4组"
			],
			"五、生活习惯": [
				"限制孩子晚上使用电子设备的时间，以提高睡眠质量",
				"限制长时间坐着，避免长时间看电视、玩电子游戏或使用电脑",
				"建立稳定的作息时间，包括固定时间起床、吃饭和睡觉",
				"确保孩子在晚上10点前上床，保证每天睡眠时间超过8小时",
				"关注孩子的睡眠质量，如入睡困难、易惊醒、流口水等问题，及时进行调整",
				"确保室内环境安全无害，避免甲醛、VOC超标以及噪声污染"
			]
		}
	},
	'1-4': {
		// 身高矮小、体重肥胖
		sug: [
			'及时到医院检查，确认身高矮小、体重肥胖是否由疾病引起。',
			'关注焦虑、压力等，避免因矮小、肥胖可能产生的自卑感。',
			'稳定饮食，调理脾胃，多吃牛肉、虾、鱼、紫菜、蛋类、奶制品、坚果、燕麦、核桃、豌豆、扁豆等，限制高糖、高脂肪和高盐的食物。',
			'避免长时间静坐，鼓励经常性的活动，低冲击性运动开始，每天30分钟以上运动时间。保证每天8小时以上的充足睡眠。',
		],
		details: {
			"一、及时就医": [
				"到医院进行检查，确认身高矮小、体重肥胖是否由疾病引起的，如遗传因素、生长激素缺乏、骨骼发育障碍、高血糖等，便于及时进行治疗。包括骨龄、甲状腺功能、微量元素、肝功能和血常规检查等。",
				"定期监测身高、体重、血压、血糖、胆固醇水平和总体状况。"
			],
			"二、积极心态": [
				"对于可能因身高矮小、体重肥胖产生的自卑感，增强孩子的自信心，维护孩子的自尊心，避免就身高和体重问题进行负面讨论。给予孩子正面的反馈和奖励，强调他们的努力和进步，而不是只关注体重变化。",
				"教育孩子关于健康饮食和运动的重要性。在家中树立良好的饮食和运动榜样，鼓励全家一起参与健康的活动，如户外徒步、骑车或共同准备健康餐食。",
				"压力、焦虑可能导致一些孩子过度进食，作为应对情绪的一种方式。关注小孩出现情绪变化、波动和焦虑等，关注是否有异常发生，如霸凌、学习压力、家庭原因等，积极沟通，做好疏导。"
			],
			"三、加强营养": [
				"了解消化和吸收情况，建议做食物不耐受检查、肠道微生态检查、幽门螺杆菌检查；",
				"刺激生长激素分泌和体重管理为目标，多吃牛肉、虾、鱼、紫菜、蛋类、奶制品、坚果、燕麦、核桃、豌豆、扁豆等高蛋白质食物、高钙食物，限制高糖、高脂肪和高盐的食物。多吃新鲜果蔬的摄入，确保膳食纤维的充分供应。",
				"孩子提供额外的营养补充，重点补充蛋白质、氨基丁酸、维生素D、钙锌等，4岁以上可以服用肠内营养粉。",
				"鼓励多喝水，保持充足的水分摄入，限制含糖饮料和碳酸饮料的摄入，避免让孩子养成吃零食的习惯，按时吃早餐、午餐和晚餐，避免长时间不吃或暴饮暴食。"
			],
			"四、运动锻炼": [
				"运动锻炼以刺激生长激素分泌和体重管理为目标，保证每天30分钟以上运动时间。",
				"从轻松的活动开始，如散步或简单的家务劳动，然后逐渐增加运动强度和持续时间。",
				"多做低冲击性的运动，例如游泳、骑自行车、慢跑、跳舞、体操、踢毽子、瑜伽、乒乓球、羽毛球等。",
				"根据孩子的兴趣和体质，设计一个包含有氧、力量和灵活性训练的运动计划。",
				"起床后，建议做一些简单的伸展运动，可以帮助唤醒肌肉和关节，增加身体的柔韧性。如，手臂上举伸展、腿部伸展、躯干扭转等，每天坚持5分钟。",
				"建议Tabata锻炼：垫脚尖跳、开合跳、挺身跳、开合左右交叉跳、前后跳、前后交叉跳、左右跳、深蹲跳，每个动作30秒，一组4分钟，每天2-4组。"
			],
			"五、生活习惯": [
				"限制晚上使用电子设备的时间，帮助孩子更好地入睡。",
				"限制久坐的时间，包括看电视、玩电子游戏和使用电脑。",
				"建立规律的作息时间，包括定时的起床、吃饭和睡觉时间。",
				"晚上10点前上床睡觉，保证每天睡眠时间超过8小时。",
				"重视孩子睡眠质量，关注入睡难、易惊醒、流口水等情况，及时调理。",
				"保证室内环境安全，避免甲醛、VOC超标、噪声污染等。"
			]
		}
	},
	'2-1': {
		// 身高偏矮、体重消瘦
		sug: [
			'及时到医院检查，确认身高偏矮、体重消瘦是否由疾病引起。',
			'维护孩子自尊心，避免身高和体重被标签化，如小矮个等。',
			'调理脾胃，少食多餐，多吃牛肉、虾、鱼、紫菜、蛋类、奶制品、坚果、燕麦、核桃、豌豆、扁豆等。',
			'多做开合跳、高抬腿、前后跳等跳跃运动，多参加团体运动，每天30分钟以上运动时间。保证每天8小时以上的充足睡眠。',
		],
		details: {
			"一、及时就医": [
				"到医院进行检查，确认身高偏矮、体重消瘦是否由疾病引起，如生长激素缺乏、骨骼发育障碍等，便于及时进行治疗。包括骨龄、甲状腺功能、微量元素、肝功能和血常规检查等。",
				"定期监测身高、体重和总体健康状况。"
			],
			"二、积极心态": [
				"对于可能因身高和体重而产生的心理压力，维护孩子的自尊心，避免身高和体重问题标签化，如小矮个等。",
				"鼓励孩子参与各种活动，发展兴趣和技能，提高自信。鼓励参加团体运动，如足球、篮球、腰旗橄榄球等。",
				"帮助孩子发现并发展他们的兴趣和爱好，有助于建立自信，并转移对体重和身高的关注。",
				"关注小孩出现情绪变化、波动和焦虑等，关注是否有异常事件发生，如霸凌、学习压力、家庭原因等，积极沟通，做好疏导。"
			],
			"三、加强营养": [
				"了解消化吸收情况，建议做食物不耐受检查、肠道微生态检查、幽门螺杆菌检查；",
				"刺激生长激素的分泌和增加体重为目标，调理脾胃，少时多餐，多吃牛肉、虾、鱼、紫菜、蛋类、奶制品、坚果、燕麦、核桃、豌豆、扁豆等高蛋白、高能量的食物。",
				"提供额外的营养补充，重点补充蛋白质、氨基丁酸、维生素D、钙锌等，4岁以上可以服用肠内营养粉，促进骨骼生长和健康。",
				"鼓励多喝水，保持充足的水分摄入，尽量做到定时定量地进餐，避免长时间不吃或暴饮暴食。"
			],
			"四、运动锻炼": [
				"运动锻炼以刺激生长激素分泌、增加体重为目标，保证每天30分钟以上运动时间。",
				"多做跳跃性和拉伸性运动，如游泳、篮球、跳绳、瑜伽、普拉提、体操等；避免进行负重过大的运动，如跑步、举重等。",
				"起床后，建议做一些简单的伸展运动，可以帮助唤醒肌肉和关节，增加身体的柔韧性。例如，手臂上举伸展、腿部伸展、躯干扭转等，每天坚持5分钟。",
				"建议跳绳和tabata锻炼。跳绳每天4组，每组60秒。Tabata锻炼：垫脚尖跳、开合跳、挺身跳、开合左右交叉跳、前后跳、前后交叉跳、左右跳、深蹲跳，每个动作30秒，一组4分钟，每天2-4组。",
				"鼓励多参加户外运动，如骑自行车、健步、游泳、跆拳道等。"
			],
			"五、生活习惯": [
				"限制晚上使用电子设备的时间，帮助孩子更好地入睡。",
				"晚上10点前上床睡觉，保证每天睡眠时间超过8小时。",
				"重视孩子睡眠质量，关注入睡难、易惊醒、流口水等情况，及时调理。",
				"保证室内环境安全，避免甲醛、VOC超标、噪声污染等。"
			]
		}
	},
	'2-2': {
		// 身高偏矮、体重正常
		sug: [
			'及时到医院检查，确认身高偏矮是否由疾病引起。',
			'增强孩子的自信心，避免因偏矮被标签化、语言讨论等。',
			'均衡饮食，多吃牛肉、虾、鱼、紫菜、蛋类、奶制品、坚果、燕麦、核桃、豌豆、扁豆等。',
			'多做抗阻训练和拉伸运动，每天30分钟以上运动时间。保证每天8小时以上的充足睡眠。',
		],
		details: {
			"一、及时就医": [
				"到医院进行检查，确认身高偏矮是否由疾病引起的，如生长激素缺乏、骨骼发育障碍等，便于及时进行治疗。包括骨龄、甲状腺功能、微量元素、肝功能和血常规检查等。",
				"定期监测身高、体重和总体健康状况。"
			],
			"二、积极心态": [
				"针对身高偏矮可能引发的焦虑、社交回避，增强孩子自信心，防止身高问题导致的标签化谈论。",
				"鼓励参与多样活动及团体运动（如足球、篮球、腰旗橄榄球）以培养兴趣、技能和自信心。",
				"留意情绪波动、焦虑等迹象，关注是否存在霸凌、学业、家庭问题，主动沟通并提供心理支持。"
			],
			"三、加强营养": [
				"进行食物不耐受检查、肠道微生态、幽门螺杆菌检查，评估消化吸收状况。",
				"平衡饮食，侧重食用牛肉、虾、鱼类、紫菜、蛋奶、坚果、燕麦、核桃、豆类等富含蛋白质与钙质的食物，促进生长激素分泌及体重维持。",
				"补充蛋白质、氨基丁酸、维生素D、钙锌等关键营养素，助力骨骼成长。",
				"保证充足水分摄取，定时定量饮食，避免饥饿过久或暴饮暴食。"
			],
			"四、运动锻炼": [
				"以促进生长激素分泌和体重控制为目标，确保每日至少30分钟运动。",
				"推荐跳跃与拉伸类运动，如游泳、篮球、跳绳、瑜伽、普拉提、体操，避免高强度负重训练。",
				"早晨执行简短伸展运动，如臂、腿伸展，躯干扭动，持续5分钟，提升身体柔韧度。",
				"实行跳绳和Tabata训练计划：跳绳每日4组，每组60秒；Tabata含多种跳跃动作，每项30秒，一组4分钟，每日2-4组。"
			],
			"五、生活习惯": [
				"限制晚间电子设备使用，助于改善睡眠。",
				"确保每晚10点前入睡，保证8小时以上充足睡眠。",
				"关注睡眠质量，对入睡障碍、易醒、异态睡眠等问题及时干预。",
				"营造安全室内环境，避免甲醛、VOC超标及噪音污染。"
			]
		}
	},
	'2-3': {
		// 身高偏矮、体重偏重
		sug: [
			'及时到医院检查，确认身高偏矮、体重偏重是否由疾病引起。',
			'增强孩子自信心，一起面对如自我认同困惑、社交逃避等。',
			'调理脾胃，限制高糖、高脂肪和高盐的食物，多吃牛肉、虾、鱼、紫菜、蛋类、奶制品、坚果、燕麦、核桃、豌豆、扁豆等。',
			'多做低冲击性的运动，每天30分钟以上运动时间。保证每天8小时以上的充足睡眠。',
		],
		details: {
			"一、及时就医": [
				"到医院进行检查，确认身高偏矮、体重偏重是否由疾病引起，如生长激素缺乏、骨骼发育障碍等，便于及时进行治疗。包括骨龄、甲状腺功能、微量元素、肝功能和血常规检查等。",
				"定期监测身高、体重、血压、血糖、胆固醇水平和总体状况。"
			],
			"二、积极心态": [
				"对于可能因身高偏矮、体重偏重产生的自卑感，增强孩子的自信心，维护孩子的自尊心，避免就身高和体重问题进行负面讨论。与孩子沟通，了解他们的感受，帮助建立积极的自我形象。",
				"教育孩子关于健康饮食和运动的重要性。在家中树立良好的饮食和运动榜样，鼓励全家一起参与健康的活动，如户外徒步、骑车或共同准备健康餐食。",
				"关注小孩出现情绪变化、波动和焦虑等，关注是否有异常事件发生，如霸凌、学习压力、家庭原因等，积极沟通，做好疏导。"
			],
			"三、加强营养": [
				"了解消化和吸收情况，建议做食物不耐受检查、肠道微生态检查、幽门螺杆菌检查；",
				"合理设定体重目标，避免使用极端的节食手段。",
				"刺激生长激素分泌和控制体重为目标，调理脾胃，少食多餐，限制高糖、高脂肪和高盐的食物，多吃牛肉、虾、鱼、紫菜、蛋类、奶制品、坚果、燕麦、核桃、豌豆、扁豆等。",
				"提供额外的营养补充，重点补充蛋白质、氨基丁酸、维生素D、钙锌等，促进骨骼生长和健康。",
				"鼓励多喝水，保持充足的水分摄入，避免让孩子养成吃零食的习惯，尽量做到定时定量地进餐，避免长时间不吃或暴饮暴食。"
			],
			"四、运动锻炼": [
				"运动锻炼以刺激生长激素分泌、控制体重为目标，至少每周进行5天的中等强度运动，如快走、游泳、跳舞、乒乓球、羽毛球等，每次至少持续30分钟以上。",
				"多做低冲击性的运动，例如游泳、骑自行车、慢跑、跳舞、体操、踢毽子、瑜伽等。多参加团体运动如篮球、足球等。",
				"起床后，建议做一些简单的伸展运动，可以帮助唤醒肌肉和关节，增加身体的柔韧性。如，手臂上举伸展、腿部伸展、躯干扭转等，每天坚持5分钟。",
				"建议跳绳和tabata锻炼。跳绳每天4组，每组60秒。Tabata锻炼：垫脚尖跳、开合跳、挺身跳、开合左右交叉跳、前后跳、前后交叉跳、左右跳、深蹲跳，每个动作30秒，一组4分钟，每天2-4组。"
			],
			"五、生活习惯": [
				"限制晚上使用电子设备的时间，帮助孩子更好地入睡。",
				"限制久坐的时间，包括看电视、玩电子游戏和使用电脑。",
				"建立规律的作息时间，包括定时的起床、吃饭和睡觉时间。",
				"晚上10点前上床睡觉，保证每天睡眠时间超过8小时。",
				"重视孩子睡眠质量，关注入睡难、易惊醒、流口水等情况，及时调理。",
				"保证室内环境安全，避免甲醛、VOC超标、噪声污染等。"
			]
		}
	},
	'2-4': {
		// 身高偏矮、体重肥胖
		sug: [
			'及时到医院检查，确认身高偏矮、体重肥胖是否由疾病引起。',
			'维护孩子自尊心，避免因偏矮、肥胖可能会产生的自卑感。',
			'稳定饮食，限制高糖、高脂肪和高盐的食物，多吃牛肉、虾、鱼、紫菜、蛋类、奶制品、坚果、燕麦、核桃、豌豆、扁豆等。',
			'鼓励经常性的活动，低冲击性的运动开始，每天30分钟以上运动时间。保证每天8小时以上的充足睡眠。',
		],
		details: {
			"一、及时就医": [
				"到医院进行检查，确认身高偏矮、体重肥胖是否由疾病引起的，如遗传因素、生长激素缺乏、骨骼发育障碍、高血糖等，便于及时进行治疗。包括不限于骨龄、甲状腺功能、微量元素、肝功能和血常规检查等。",
				"定期监测身高、体重、血压、血糖、胆固醇水平和总体状况。"
			],
			"二、积极心态": [
				"对于可能因身高偏矮、体重肥胖产生的自卑感，维护孩子的自尊心，避免就身高和体重问题进行负面讨论，如小矮个、小胖墩等。给予孩子正面的反馈和奖励，强调他们的努力和进步，而不是只关注体重变化。",
				"教育孩子关于健康饮食和运动的重要性。在家中树立良好的饮食和运动榜样，鼓励全家一起参与健康的活动，如户外徒步、骑车或共同准备健康餐食。",
				"压力、焦虑可能导致一些孩子过度进食，作为应对情绪的一种方式。关注小孩出现情绪变化、波动和焦虑等，关注是否有异常发生，如霸凌、学习压力、家庭原因等，积极沟通，做好疏导。"
			],
			"三、加强营养": [
				"了解消化和吸收情况，建议做食物不耐受检查、肠道微生态检查、幽门螺杆菌检查；",
				"合理设定体重目标。避免极端的节食或快速减肥方法",
				"刺激生长激素分泌和体重管理为目标，限制高糖、高脂肪和高盐的食物，多吃牛肉、虾、鱼、紫菜、蛋类、奶制品、坚果、燕麦、核桃、豌豆、扁豆等高蛋白质食物、高钙食物。增加新鲜果蔬的摄入，确保膳食纤维的充分供应。",
				"提供额外的营养补充，重点补充蛋白质、氨基丁酸、维生素D、钙锌等，促进骨骼生长和健康。",
				"鼓励多喝水，保持充足的水分摄入，限制含糖饮料和碳酸饮料的摄入，避免让孩子养成吃零食的习惯，按时吃早餐，午餐和晚餐，避免长时间不吃或暴饮暴食。"
			],
			"四、运动锻炼": [
				"运动锻炼以刺激生长激素分泌和体重管理为目标，保证每天30分钟以上运动时间。",
				"从轻松的活动开始，如散步或简单的家务劳动，然后逐渐增加运动强度和持续时间。",
				"多做低冲击性的运动，例如游泳、骑自行车、慢跑、跳舞、体操、踢毽子、瑜伽、乒乓球、羽毛球等。",
				"根据孩子的兴趣和体质，设计一个包含有氧、力量和灵活性训练的运动计划。",
				"起床后，建议做一些简单的伸展运动，可以帮助唤醒肌肉和关节，增加身体的柔韧性。如，手臂上举伸展、腿部伸展、躯干扭转等，每天坚持5分钟。",
				"建议Tabata锻炼：垫脚尖跳、开合跳、挺身跳、开合左右交叉跳、前后跳、前后交叉跳、左右跳、深蹲跳，每个动作30秒，一组4分钟，每天2-4组。"
			],
			"五、生活习惯": [
				"限制晚上使用电子设备的时间，帮助孩子更好地入睡。",
				"限制久坐的时间，包括看电视、玩电子游戏和使用电脑。",
				"建立规律的作息时间，包括定时的起床、吃饭和睡觉时间。",
				"晚上10点前上床睡觉，保证每天睡眠时间超过8小时。",
				"重视孩子睡眠质量，关注入睡难、易惊醒、流口水等情况，及时调理。",
				"保证室内环境安全，避免甲醛、VOC超标、噪声污染等。"
			]
		}
	},
	'3-1': {
		// 身高标准、体重消瘦
		sug: [
			'及时到医院进行检查，确认体重消瘦是否由疾病引起。',
			'关注小孩情绪变化，避免小孩因体重问题产生的困扰。',
			'调理脾胃，少食多餐，多吃牛肉、虾、鱼、紫菜、蛋类、奶制品、坚果、燕麦、核桃、豌豆、扁豆等，避免采取极端的饮食措施或强迫孩子进食。',
			'多做开合跳、前后跳、高抬腿等跳跃运动，多参加团体运动，每天30分钟以上运动时间。保证每天8小时以上的充足睡眠。',
		],
		details: {
			"一、及时就医": [
				"到医院进行检查，确认体重消瘦是否由疾病引起，如生长激素缺乏、骨骼发育障碍等，便于及时进行治疗。包括骨龄、甲状腺功能、微量元素、肝功能和血常规检查等。",
				"定期监测身高、体重和总体健康状况。"
			],
			"二、积极心态": [
				"鼓励孩子参与各种活动，发展兴趣和技能，提高自信。鼓励参加团体运动，如足球、篮球、腰旗橄榄球等。",
				"帮助孩子发现并发展他们的兴趣和爱好，有助于建立自信，并转移对体重的关注。",
				"关注小孩出现情绪变化、波动和焦虑等，关注是否有异常事件发生，如霸凌、学习压力、家庭原因等，积极沟通，做好疏导。"
			],
			"三、加强营养": [
				"了解消化吸收情况，建议做食物不耐受检查、肠道微生态检查、幽门螺杆菌检查。",
				"避免采取极端的饮食措施或强迫孩子进食。",
				"增加体重为目标，调理脾胃，少时多餐，适当增加牛肉、虾、鱼、紫菜、蛋类、奶制品、坚果、燕麦、核桃、豌豆、扁豆等高蛋白、高能量的食物。",
				"提供额外的营养补充，重点补充蛋白质、氨基丁酸、维生素D、钙锌等，4岁以上可以服用肠内营养粉。"
			],
			"四、运动锻炼": [
				"运动锻炼增加体重为目标，保证每天40分钟以上运动时间。",
				"多做跳跃性和拉伸性运动，如游泳、篮球、跳绳、瑜伽、普拉提、体操等；",
				"多做力量训练，如俯卧撑、深蹲、仰卧起坐等，增强力量。",
				"建议跳绳和tabata锻炼。跳绳每天4组，每组60秒。Tabata锻炼：垫脚尖跳、开合跳、挺身跳、开合左右交叉跳、前后跳、前后交叉跳、左右跳、深蹲跳，每个动作30秒，一组4分钟，每天2-4组。"
			],
			"五、生活习惯": [
				"限制晚上使用电子设备的时间，帮助孩子更好地入睡。",
				"晚上10点前上床睡觉，保证每天睡眠时间超过8小时。",
				"重视孩子睡眠质量，关注入睡难、易惊醒、流口水等情况，及时调理。",
				"保证室内环境安全，避免甲醛、VOC超标、噪声污染等。"
			]
		}
	},
	'3-2': {
		// 身高标准、体重正常
		sug: [
			'定期检查，监测身高、体重和总体健康状况。',
			'鼓励孩子参与社交活动，如参加兴趣小组、社区活动等。',
			'均衡饮食，摄入多样化、营养丰富的食物，多吃牛肉、虾、鱼、紫菜、蛋类、奶制品、坚果、燕麦、核桃、豌豆、扁豆等。',
			'每天进行40分钟以上中等强度的身体活动。保证每天8小时以上的充足睡眠。',
		],
		details: {
			"一、定期监测": [
				"监测身高、体重和总体健康状况。"
			],
			"二、积极心态": [
				"鼓励孩子参与社交活动，如参加兴趣小组、社区活动等，这有助于他们建立友谊，发展社交技能。",
				"关注小孩出现情绪变化、波动和焦虑等，关注是否有异常事件发生，如霸凌、学习压力、家庭原因等，积极沟通，做好疏导。"
			],
			"三、加强营养": [
				"均衡饮食，多摄入营养丰富、多样化食物，多吃牛肉、虾、鱼、紫菜、蛋类、奶制品、坚果、燕麦、核桃、豌豆、扁豆等高蛋白质食物、高钙食物。",
				"额外补充一些营养素，重点补充蛋白质、氨基丁酸、维生素D、钙锌等。",
				"鼓励多喝水，保持充足的水分摄入，尽量做到定时定量地进餐，避免长时间不吃或暴饮暴食。"
			],
			"四、运动锻炼": [
				"鼓励孩子每天进行40分钟以上中等强度的身体活动，如跑步、游泳、骑自行车、球类运动等。",
				"起床后，建议做一些简单的伸展运动，可以帮助唤醒肌肉和关节，增加身体的柔韧性。如，手臂上举伸展、腿部伸展、躯干扭转等，每天坚持5分钟。",
				"建议Tabata锻炼：垫脚尖跳、开合跳、挺身跳、开合左右交叉跳、前后跳、前后交叉跳、左右跳、深蹲跳，每个动作30秒，一组4分钟，每天2-4组。"
			],
			"五、生活习惯": [
				"限制晚上使用电子设备的时间，帮助孩子更好地入睡。",
				"晚上10点前上床睡觉，保证每天睡眠时间超过8小时。",
				"重视孩子睡眠质量，关注入睡难、易惊醒、流口水等情况，及时调理。",
				"保证室内环境安全，避免甲醛、VOC超标、噪声污染等。"
			]
		}
	},
	'3-3': {
		// 身高标准、体重偏重
		sug: [
			'定期监测身高、体重、血压、血糖和胆固醇等。',
			'增强孩子自信心，避免偏重可能产生的自卑感，如自我认同困惑、社交逃避等。',
			'调理脾胃，调整饮食，均衡营养，限制高糖、高脂肪和高盐的食物，多吃牛肉、虾、鱼、紫菜、蛋类、奶制品、坚果、燕麦、核桃、豌豆、扁豆等。',
			'每天40分钟以上运动时间。每天8小时以上的充足睡眠。',
		],
		details: {
			"一、定期监测": [
				"监测身高、体重、血压、血糖、胆固醇水平和总体状况。"
			],
			"二、积极心态": [
				"对于可能因体重偏重产生的自卑感，增强孩子的自信心，维护孩子的自尊心，避免就身高和体重问题进行负面讨论。与孩子沟通，了解他们的感受，帮助他们建立积极的自我形象。",
				"教育孩子关于健康饮食和运动的重要性。在家中树立良好的饮食和运动榜样，鼓励全家一起参与健康的活动，如户外徒步、骑车或共同准备健康餐食。",
				"关注小孩出现情绪变化、波动和焦虑等，关注是否有异常事件发生，如霸凌、学习压力、家庭原因等，积极沟通，做好疏导。"
			],
			"三、加强营养": [
				"了解消化和吸收情况，建议做食物不耐受检查、肠道微生态检查、幽门螺杆菌检查；",
				"合理设定体重目标，避免使用极端的节食手段。",
				"控制体重为目标，调理脾胃，少食多餐，均衡营养，减少高热量、高脂肪、高糖分的食品。",
				"多吃牛肉、虾、鱼、紫菜、蛋类、奶制品、坚果、燕麦、核桃、豌豆、扁豆等高蛋白质食物、高钙食物。",
				"鼓励多喝水，保持充足的水分摄入，避免让孩子养成吃零食的习惯，尽量做到定时定量地进餐，避免长时间不吃或暴饮暴食。"
			],
			"四、运动锻炼": [
				"控制体重为目标，至少每周进行5天的中等强度运动，如快走、游泳、跳舞、乒乓球、羽毛球等，每次持续40分钟以上。",
				"起床后，建议做一些简单的伸展运动，可以帮助唤醒肌肉和关节，增加身体的柔韧性。如，手臂上举伸展、腿部伸展、躯干扭转等，每天坚持5分钟。",
				"建议Tabata锻炼：垫脚尖跳、开合跳、挺身跳、开合左右交叉跳、前后跳、前后交叉跳、左右跳、深蹲跳，每个动作30秒，一组4分钟，每天2-4组。"
			],
			"五、生活习惯": [
				"限制晚上使用电子设备的时间，帮助孩子更好地入睡。",
				"限制久坐的时间，包括看电视、玩电子游戏和使用电脑。",
				"建立规律的作息时间，包括定时的起床、吃饭和睡觉时间。",
				"晚上10点前上床睡觉，保证每天睡眠时间超过8小时。",
				"重视孩子睡眠质量，关注入睡难、易惊醒、流口水等情况，及时调理。",
				"保证室内环境安全，避免甲醛、VOC超标、噪声污染等。"
			]
		}
	},
	'3-4': {
		// 身高标准、体重肥胖
		sug: [
			'及时到医院检查，确认体重肥胖是否由疾病引起。',
			'维护孩子自尊心，避免因体重肥胖可能会产生的自卑感。',
			'调理脾胃，稳定饮食，限制高糖、高脂肪和高盐的食物，多吃牛肉、虾、鱼、紫菜、蛋类、奶制品、坚果、燕麦、核桃、豌豆、扁豆等。',
			'鼓励经常性的活动，低冲击性的运动开始，每天40分钟以上运动时间。保证每天8小时以上的充足睡眠。',
		],
		details: {
			"一、及时就医": [
				"到医院进行检查，确认体重肥胖是否由疾病引起的，如遗传因素、生长激素缺乏、骨骼发育障碍、高血糖等，便于及时进行相关治疗。",
				"定期监测身高、体重、血压、血糖、胆固醇水平和总体状况。"
			],
			"二、积极心态": [
				"对于可能因体重肥胖产生的自卑感，要维护孩子的自尊心，避免体重问题进行负面讨论，如小胖墩等。给予孩子正面的反馈和奖励，强调他们的努力和进步，而不是只关注体重变化。",
				"教育孩子关于健康饮食和运动的重要性。在家中树立良好的饮食和运动榜样，鼓励全家一起参与健康的活动，如户外徒步、骑车或共同准备健康餐食。",
				"压力、焦虑可能导致一些孩子过度进食，作为应对情绪的一种方式。关注小孩出现情绪变化、波动和焦虑等，关注是否有异常发生，如霸凌、学习压力、家庭原因等，积极沟通，做好疏导。"
			],
			"三、加强营养": [
				"了解消化和吸收情况，建议做食物不耐受检查、肠道微生态检查、幽门螺杆菌检查；",
				"合理设定体重目标。避免极端的节食或快速减肥方法。",
				"以体重管理为目标，限制高糖、高脂肪和高盐的食物，多吃牛肉、虾、鱼、紫菜、蛋类、奶制品、坚果、燕麦、核桃、豌豆、扁豆等高蛋白质食物、高钙食物。增加新鲜果蔬的摄入，确保膳食纤维的充分供应。",
				"鼓励多喝水，保持充足的水分摄入，限制含糖饮料和碳酸饮料的摄入，避免让孩子养成吃零食的习惯，按时吃早餐，午餐和晚餐，避免长时间不吃或暴饮暴食。"
			],
			"四、运动锻炼": [
				"以体重管理为目标，保证每天40分钟以上运动时间。",
				"从轻松的活动开始，如散步或简单的家务劳动，然后逐渐增加运动强度和持续时间。",
				"多做低冲击性的运动，例如游泳、骑自行车、慢跑、跳舞、体操、踢毽子、瑜伽、乒乓球、羽毛球等。",
				"根据孩子的兴趣和体质，设计一个包含有氧、力量和灵活性训练的运动计划。",
				"起床后，建议做一些简单的伸展运动，可以帮助唤醒肌肉和关节，增加身体的柔韧性。如，手臂上举伸展、腿部伸展、躯干扭转等，每天坚持5分钟。",
				"建议做Tabata锻炼：垫脚尖跳、开合跳、挺身跳、开合左右交叉跳、前后跳、前后交叉跳、左右跳、深蹲跳，每个动作30秒，一组4分钟，每天2-4组。"
			],
			"五、生活习惯": [
				"限制晚上使用电子设备的时间，帮助孩子更好地入睡。",
				"限制久坐的时间，包括看电视、玩电子游戏和使用电脑。",
				"建立规律的作息时间，包括定时的起床、吃饭和睡觉时间。",
				"晚上10点前上床睡觉，保证每天睡眠时间超过8小时。",
				"重视孩子睡眠质量，关注入睡难、易惊醒、流口水等情况，及时调理。",
				"保证室内环境安全，避免甲醛、VOC超标、噪声污染等。"
			]
		}
	},
	'4-1': {
		// 身高偏高、体重消瘦
		sug: [
			'及时到医院检查，确认体重消瘦是否由疾病引起。',
			'关注小孩情绪变化，避免小孩因体重问题产生的困扰。',
			'调理脾胃，少食多餐，多吃牛肉、虾、鱼、紫菜、蛋类、奶制品、坚果、燕麦、核桃、豌豆、扁豆等，避免采取极端的饮食措施或强迫孩子进食。',
			'多做开合跳、前后跳、高抬腿等跳跃运动，多参加团体运动，每天30分钟以上运动时间。保证每天8小时以上的充足睡眠。',
		],
		details: {
			"一、及时就医": [
				"到医院进行检查，确认体重消瘦是否由疾病引起，如消化系统、内分泌系统等，以便及时治疗。",
				"检查骨龄。",
				"定期监测身高、体重和总体健康状况，特别关注增高速度、身体比例等。"
			],
			"二、积极心态": [
				"鼓励孩子参与多种活动，促进兴趣与技能的发展，提升自信心。推荐加入团队运动，如足球、篮球、腰旗橄榄球等。",
				"协助孩子发掘并培养个人兴趣和爱好，有助于自信心构建，同时分散对体重的过度关注。",
				"留意孩子的情绪变化、波动及焦虑迹象，关注是否存在霸凌、学业压力、家庭问题等异常情况，通过积极沟通进行有效引导与心理疏导。"
			],
			"三、加强营养": [
				"评估消化吸收状态，考虑进行食物不耐受检查、肠道微生态检查及幽门螺杆菌检查。",
				"避免采取极端饮食方法，不强迫进食，创造轻松饮食环境。",
				"以增重为目标，调整脾胃功能，采取少量多餐制，适量增加牛肉、虾、鱼、紫菜、蛋类、奶制品、坚果、燕麦、核桃、豌豆、扁豆等富含蛋白质和能量的食物。",
				"补充关键营养素，如蛋白质、氨基丁酸、维生素D、钙锌等，4岁以上儿童可考虑添加肠内营养粉。"
			],
			"四、运动锻炼": [
				"以增重为运动目的，确保每日至少40分钟的运动时间。",
				"参与能提升协调性的运动，如乒乓球、骑行、游泳、篮球、足球、体操等。",
				"强化力量训练，如俯卧撑、深蹲、仰卧起坐等，增强肌肉力量。",
				"实施Tabata训练：包含垫脚尖跳、开合跳、挺身跳、开合左右交叉跳、前后跳、前后交叉跳、左右跳、深蹲跳，每项30秒，组成一组4分钟，每天3-5组。"
			],
			"五、生活习惯": [
				"限制晚间使用电子设备，助于改善睡眠质量。",
				"晚上10点前睡觉，确保每天睡眠超过8小时。",
				"关注睡眠质量，对入睡困难、易醒、流涎等问题及时采取措施调整。",
				"确保居住环境安全无害，避免甲醛、VOC超标、噪音污染等潜在风险。"
			]
		}
	},
	'4-2': {
		// 身高偏高、体重正常
		sug: [
			'检查骨龄。定期检查，监测身高、体重和总体健康状况。',
			'鼓励孩子参与社交活动，如参加兴趣小组、社区活动等。',
			'均衡饮食，摄入多样化、营养丰富的食物，多吃牛肉、虾、鱼、紫菜、蛋类、奶制品、坚果、燕麦、核桃、豌豆、扁豆等。',
			'每天进行40分钟以上中等强度的运动，如跑步、游泳、骑自行车、球类运动等。保证每天8小时以上的充足睡眠。',
		],
		details: {
			"一、检查骨龄": [
				"定期监测身高、体重和总体健康状况。",
				"重点关注增高速度、身体比例等。"
			],
			"二、积极心态": [
				"鼓励孩子参与社交活动，如参加兴趣小组、社区活动等，这有助于他们建立友谊，发展社交技能。",
				"关注小孩出现情绪变化、波动和焦虑等，关注是否有异常事件发生，如霸凌、学习压力、家庭原因等，积极沟通，做好疏导。"
			],
			"三、加强营养": [
				"均衡饮食，多摄入营养丰富、多样化食物，多吃牛肉、虾、鱼、紫菜、蛋类、奶制品、坚果、燕麦、核桃、豌豆、扁豆等高蛋白质食物、高钙食物。",
				"鼓励多喝水，保持充足的水分摄入，尽量做到定时定量地进餐，避免长时间不吃或暴饮暴食。"
			],
			"四、运动锻炼": [
				"鼓励孩子每天进行40分钟以上中等强度到高强度的身体活动，如跑步、游泳、骑自行车、球类运动等。",
				"起床后，建议做一些简单的伸展运动，可以帮助唤醒肌肉和关节，增加身体的柔韧性。如，手臂上举伸展、腿部伸展、躯干扭转等，每天坚持5分钟。",
				"建议做tabata锻炼：垫脚尖跳（30秒）、开合跳（30秒）、挺身跳（30秒）、开合左右交叉跳（30秒）、前后跳（30秒）、前后交叉跳（30秒）、左右跳（30秒）、深蹲跳（30秒），一组4分钟，每天2-4组。"
			],
			"五、生活习惯": [
				"限制晚上使用电子设备的时间，帮助孩子更好地入睡。",
				"晚上10点前上床睡觉，保证每天睡眠时间超过8小时。",
				"重视孩子睡眠质量，关注入睡难、易惊醒、流口水等情况，及时调理。",
				"保证室内环境安全，避免甲醛、VOC超标、噪声污染等。"
			]
		}

	},
	'4-3': {
		// 身高偏高、体重偏重
		sug: [
			'检查骨龄。定期监测身高、体重、血压、血糖和胆固醇等。',
			'增强孩子自信心，避免因体重偏重可能会产生的自卑感，如自我认同困惑、社交逃避等。',
			'调理脾胃，调整饮食，均衡营养，限制高糖、高脂肪和高盐的食物，多吃多吃牛肉、虾、鱼、紫菜、蛋类、奶制品、坚果、燕麦、核桃、豌豆、扁豆等。',
			'每天40分钟以上运动时间，如游泳、骑自行车、慢跑、体操等。保证每天8小时以上的充足睡眠。',
		],
		details: {
			"一、全面健康监测": [
				"检查骨龄，定期监测身高、体重、血压、血糖、胆固醇水平，关注总体健康状况",
			],
			"二、积极心态培养": [
				"对于可能因体重偏重产生的自卑感，增强孩子的自信心，避免就身高和体重问题进行负面讨论，与孩子沟通，了解他们的感受，帮助他们建立积极的自我形象。",
				"教育孩子关于健康饮食和运动的重要性，树立良好榜样，鼓励全家参与健康活动，如户外活动、共同准备健康餐食。",
				"关注孩子的情绪变化、波动和焦虑，留意异常事件（如霸凌、学习压力、家庭原因），积极沟通，做好心理疏导"
			],
			"三、加强营养摄入": [
				"了解消化和吸收情况，建议进行食物不耐受、肠道微生态、幽门螺杆菌检查",
				"设定合理体重目标，避免极端节食",
				"以控制体重为目标，调理脾胃，实行少食多餐，均衡饮食，减少高热量、高脂肪、高糖食物",
				"多吃高蛋白、高钙食物，如牛肉、虾、鱼、紫菜、蛋类、奶制品、坚果、燕麦、核桃、豌豆、扁豆",
				"鼓励多喝水，定时定量进餐，避免长时间不吃或暴饮暴食，减少零食摄入"
			],
			"四、运动锻炼计划": [
				"每周至少5天进行中等强度运动，如快走、游泳、乒乓球、羽毛球，每次40分钟以上",
				"起床后做伸展运动，如手臂上举伸展、腿部伸展、躯干扭转，每天5分钟",
				"进行Tabata锻炼：垫脚尖跳、开合跳、挺身跳、开合左右交叉跳、前后跳、前后交叉跳、左右跳、深蹲跳，每项30秒，4分钟一组，每天2-4组"
			],
			"五、良好生活习惯": [
				"限制晚上使用电子设备，利于睡眠",
				"限制久坐时间，如看电视、玩游戏、用电脑",
				"建立规律作息，定时起床、吃饭、睡觉",
				"晚上10点前上床，确保每天睡眠超8小时",
				"关注睡眠质量，及时处理入睡难、易惊醒、流口水等问题",
				"保证室内环境安全，避免甲醛、VOC超标、噪声污染"
			]
		}

	},
	'4-4': {
		// 身高偏高、体重肥胖
		sug: [
			'及时到医院检查，确认体重肥胖是否由疾病引起。',
			'维护孩子自尊心，避免因体重肥胖可能会产生的自卑感。',
			'调理脾胃，稳定饮食，限制高糖、高脂肪和高盐的食物，多吃牛肉、虾、鱼、紫菜、蛋类、奶制品、坚果、燕麦、核桃、豌豆、扁豆等。',
			'鼓励经常性的活动，低冲击性的运动开始，每天40分钟以上运动时间。保证每天8小时以上的充足睡眠。',
		],
		details: {
			"一、及时就医": [
				"到医院进行检查，确认体重肥胖是否由遗传因素、生长激素缺乏、骨骼发育障碍、高血糖等疾病引起，以便及时治疗。",
				"检查骨龄。",
				"定期监测身高、体重、血压、血糖、胆固醇水平和总体健康状况。"
			],
			"二、积极心态培养": [
				"对于可能因体重肥胖产生的自卑感，维护孩子的自尊心，避免就体重问题进行负面讨论，如使用‘小胖墩’等标签。给予孩子正面的反馈和奖励，强调他们的努力和进步，而非仅仅关注体重变化。",
				"教育孩子了解健康饮食和运动的重要性。在家中树立良好的饮食和运动榜样，鼓励全家一起参与健康的活动，如户外徒步、骑车或共同准备健康餐食。",
				"关注孩子的情绪变化、波动和焦虑，留意是否存在异常情况，如霸凌、学习压力、家庭问题等，积极沟通，做好心理疏导。"
			],
			"三、加强营养摄入": [
				"了解消化和吸收情况，建议做食物不耐受检查、肠道微生态检查、幽门螺杆菌检查。",
				"设定合理的体重管理目标，避免采用极端的节食或快速减肥方法。",
				"以控制体重为目标，限制高糖、高脂肪和高盐食物，多吃牛肉、虾、鱼、紫菜、蛋类、奶制品、坚果、燕麦、核桃、豌豆、扁豆等高蛋白质食物、高钙食物。增加新鲜果蔬摄入，确保膳食纤维充足。",
				"鼓励多喝水，保持水分摄入，限制含糖饮料和碳酸饮料，避免零食成瘾，定时吃早餐、午餐和晚餐，避免长时间不吃或暴饮暴食。"
			],
			"四、运动锻炼": [
				"以体重管理为目标，确保每天至少40分钟的运动时间。",
				"从轻松的活动开始，如散步或简单的家务劳动，然后逐渐增加运动强度和持续时间。",
				"多做低冲击运动，如游泳、骑自行车、慢跑、跳舞、体操、踢毽子、瑜伽、乒乓球、羽毛球等。",
				"根据孩子的兴趣和体质，设计一个包含有氧、力量和灵活性训练的运动计划。",
				"起床后，建议做一些简单的伸展运动，如手臂上举伸展、腿部伸展、躯干扭转等，每天坚持5分钟。",
				"建议进行Tabata锻炼：垫脚尖跳（30秒）、开合跳（30秒）、挺身跳（30秒）、开合左右交叉跳（30秒）、前后跳（30秒）、前后交叉跳（30秒）、左右跳（30秒）、深蹲跳（30秒），一组4分钟，每天1-3组。"
			],
			"五、生活习惯改善": [
				"限制晚上使用电子设备的时间，帮助孩子获得更好的睡眠质量。",
				"限制久坐时间，包括看电视、玩电子游戏和使用电脑。",
				"建立规律的作息时间，包括定时的起床、吃饭和睡觉时间。",
				"晚上10点前上床睡觉，确保每天睡眠时间超过8小时。",
				"关注孩子的睡眠质量，注意入睡难、易惊醒、流口水等问题，及时进行调整。",
				"保证室内环境安全，避免甲醛、VOC超标、噪声污染等。"
			]
		}
	},
	'5-1': {
		// 身高高、体重消瘦
		sug: [
			'及时到医院检查，确认体重消瘦是否由疾病引起。',
			'关注小孩情绪变化，避免小孩因体重问题产生的困扰。',
			'调理脾胃，少食多餐，多吃牛肉、虾、鱼、紫菜、蛋类、奶制品、坚果、燕麦、核桃、豌豆、扁豆等，避免采取极端的饮食措施或强迫孩子进食。',
			'多做开合跳、前后条、高抬腿等跳跃运动，多参加团体运动，每天30分钟以上运动时间。保证每天8小时以上的充足睡眠。',
		],
		details: {
			"一、及时就医": [
				"进行专业医疗检查，排查消化系统、内分泌系统等问题导致的体重消瘦，确保及时治疗。",
				"进行骨龄检测。",
				"定期记录并评估身高、体重增长及整体健康状态，特别关注成长速度与体型比例。"
			],
			"二、积极心态建设": [
				"鼓励孩子积极参与多样活动，如团队体育（足球、篮球、腰旗橄榄球），以提升自信和社交技能。",
				"协助孩子探索并发展个人兴趣，作为建立自信和分散体重关注的途径。",
				"密切关注情绪波动、焦虑迹象，及时识别霸凌、学业压力或家庭因素，通过有效沟通提供心理支持。"
			],
			"三、营养增强策略": [
				"进行食物不耐受、肠道微生态、幽门螺杆菌等相关检查，以了解消化吸收状况。",
				"避免采取极端饮食法，创造轻松饮食环境，避免强迫进食。",
				"制定增重计划，注重脾胃调养，采用少量多餐制，增加高蛋白、高能量食物如牛肉、海鲜、蛋奶、坚果及豆类等。",
				"考虑补充蛋白质、氨基丁酸、维生素D、钙锌等营养素，适合年龄可引入肠内营养粉。"
			],
			"四、科学运动方案": [
				"以促进体重增长为目标，确保每日至少40分钟的运动量。",
				"参与提升协调性的运动项目，如乒乓球、骑车、游泳、球类运动及体操。",
				"加入力量训练元素，如俯卧撑、深蹲、仰卧起坐，增强肌肉力量。",
				"建议做tabata锻炼：垫脚尖跳（30秒）、开合跳（30秒）、挺身跳（30秒）、开合左右交叉跳（30秒）、前后跳（30秒）、前后交叉跳（30秒）、左右跳（30秒）、深蹲跳（30秒），一组4分钟，每天3-5组。"
			],
			"五、优化生活习惯": [
				"限制晚间电子设备使用，促进优质睡眠。",
				"确保每晚10点前入睡，保证8小时以上充足睡眠。",
				"关注睡眠质量，针对入睡困难、频繁夜醒等问题采取相应措施。",
				"维护室内环境，避免有害物质超标和噪音干扰，保障儿童健康成长环境。"
			]
		}
	},
	'5-2': {
		// 身高高、体重正常
		sug: [
			'检查骨龄。定期监测身高、体重和总体健康状况。',
			'鼓励孩子参与社交活动，如参加兴趣小组、社区活动等。',
			'均衡饮食，摄入多样化、营养丰富的食物，多吃牛肉、虾、鱼、紫菜、蛋类、奶制品、坚果、燕麦、核桃、豌豆、扁豆等。',
			'每天进行40分钟以上中等强度到高强度的身体活动，如跑步、游泳、骑自行车、球类运动等。每天8小时以上的充足睡眠。',
		],
		details: {
			"一、检查骨龄": ["定期监测身高、体重和总体健康状况，重点关注增高速度、身体比例等。"],
			"二、积极心态": [
				"鼓励孩子参与社交活动，如兴趣小组、社区活动等，这有助于他们建立友谊，发展社交技能。",
				"关注小孩出现情绪变化、波动和焦虑等，关注是否有异常事件发生，如霸凌、学习压力、家庭原因等，积极沟通，做好疏导。"
			],
			"三、加强营养": [
				"均衡饮食，多摄入营养丰富、多样化食物，多吃牛肉、虾、鱼、紫菜、蛋类、奶制品、坚果、燕麦、核桃、豌豆、扁豆等高蛋白质食物、高钙食物。",
				"鼓励多喝水，保持充足的水分摄入，尽量做到定时定量地进餐，避免长时间不吃或暴饮暴食。"
			],
			"四、运动锻炼": [
				"鼓励孩子每天进行40分钟以上中等强度到高强度的身体活动，如跑步、游泳、骑自行车、球类运动等。",
				"起床后，建议做一些简单的伸展运动，可以帮助唤醒肌肉和关节，增加身体的柔韧性。如，手臂上举伸展、腿部伸展、躯干扭转等，每天坚持5分钟。",
				"建议做tabata锻炼：垫脚尖跳（30秒）、开合跳（30秒）、挺身跳（30秒）、开合左右交叉跳（30秒）、前后跳（30秒）、前后交叉跳（30秒）、左右跳（30秒）、深蹲跳（30秒），一组4分钟，每天2-4组。"
			],
			"五、生活习惯": [
				"限制晚上使用电子设备的时间，帮助孩子更好地入睡。",
				"晚上10点前上床睡觉，保证每天睡眠时间超过8小时。",
				"重视孩子睡眠质量，关注入睡难、易惊醒、流口水等情况，及时调理。",
				"保证室内环境安全，避免甲醛、VOC超标、噪声污染等。"
			]
		}
	},
	'5-3': {
		// 身高高、体重偏重
		sug: [
			'检查骨龄。定期监测身高、体重、血压、血糖和胆固醇等。',
			'增强孩子自信心，避免因体重偏重可能会产生的自卑感。',
			'调理脾胃，调整饮食，均衡营养，限制高糖、高脂肪和高盐的食物，多吃牛肉、虾、鱼、紫菜、蛋类、奶制品、坚果、燕麦、核桃、豌豆、扁豆等高蛋白质、高钙食物。',
			'保证每天40分钟以上运动时间，如游泳、骑自行车、慢跑、体操等。每天8小时以上的充足睡眠。',
		],
		details: {
			"一、检查骨龄": ["定期监测身高、体重和总体健康状况，重点关注增高速度、身体比例等。"],
			"二、积极心态": [
				"对于可能因体重偏重产生的自卑感，要增强孩子的自信心，避免就身高和体重问题进行负面讨论。与孩子沟通，了解他们的感受，帮助他们建立积极的自我形象。",
				"教育孩子关于健康饮食和运动的重要性。在家中树立良好的饮食和运动榜样，鼓励全家一起参与健康的活动，如户外徒步、骑车或共同准备健康餐食。",
				"关注小孩出现情绪变化、波动和焦虑等，关注是否有异常事件发生，如霸凌、学习压力、家庭原因等，积极沟通，做好疏导。"
			],
			"三、加强营养": [
				"了解消化和吸收情况，建议做食物不耐受检查、肠道微生态检查、幽门螺杆菌检查；",
				"合理设定体重目标，避免使用极端的节食手段。",
				"控制体重为目标，调理脾胃，少食多餐，均衡营养，减少高热量、高脂肪、高糖分的食品。",
				"多吃牛肉、虾、鱼、紫菜、蛋类、奶制品、坚果、燕麦、核桃、豌豆、扁豆等高蛋白质食物、高钙食物。",
				"鼓励多喝水，保持充足的水分摄入，避免让孩子养成吃零食的习惯，尽量做到定时定量地进餐，避免长时间不吃或暴饮暴食。",
			],
			"四、运动锻炼": [
				"控制体重为目标，至少每周进行5天的中等强度运动，如快走、游泳、乒乓球、羽毛球等，每次持续40分钟以上。",
				"起床后，建议做一些简单的伸展运动，可以帮助唤醒肌肉和关节，增加身体的柔韧性。如，手臂上举伸展、腿部伸展、躯干扭转等，每天坚持5分钟。",
				"建议做tabata锻炼：垫脚尖跳（30秒）、开合跳（30秒）、挺身跳（30秒）、开合左右交叉跳（30秒）、前后跳（30秒）、前后交叉跳（30秒）、左右跳（30秒）、深蹲跳（30秒），一组4分钟，每天2-4组。",
			],
			"五、生活习惯": [
				"限制晚上使用电子设备的时间，帮助孩子更好地入睡。",
				"限制久坐的时间，包括看电视、玩电子游戏和使用电脑。",
				"建立规律的作息时间，包括定时的起床、吃饭和睡觉时间。",
				"晚上10点前上床睡觉，保证每天睡眠时间超过8小时。",
				"重视孩子睡眠质量，关注入睡难、易惊醒、流口水等情况，及时调理。",
				"保证室内环境安全，避免甲醛、VOC超标、噪声污染等。"
			]
		}
	},
	'5-4': {
		// 身高高、体重肥胖
		sug: [
			'及时到医院检查，确认体重肥胖是否由疾病引起。',
			'关注孩子焦虑、压力等情况，避免因肥胖可能产生的自卑感。',
			'调理脾胃，稳定饮食，限制高糖、高脂肪和高盐的食物，多吃牛肉、虾、鱼、紫菜、蛋类、奶制品、坚果、燕麦、核桃、豌豆、扁豆等。',
			'鼓励经常性的活动，低冲击性的运动开始，每天40分钟以上运动时间。保证每天8小时以上的充足睡眠。',
		],
		details: {
			"一、及时就医": [
				"到医院进行检查，确认体重肥胖是否由遗传因素、生长激素缺乏、骨骼发育障碍、高血糖等疾病引起，以便及时治疗",
				"进行骨龄检查",
				"定期监测身高、体重、血压、血糖、胆固醇水平以及总体健康状况"
			],
			"二、积极心态": [
				"对于可能因体重肥胖产生的自卑感，要维护孩子的自尊心，避免就体重问题进行负面讨论，如使用‘小胖墩’等称呼。给予孩子正面的反馈和奖励，强调他们的努力和进步，而非仅仅关注体重的变化",
				"教育孩子了解健康饮食和运动的重要性，家长自身要树立良好的饮食和运动习惯，鼓励全家一起参与户外活动，如徒步、骑车或共同准备健康餐食",
				"关注孩子的情绪变化，如压力、焦虑可能导致过度进食。留意是否有异常情况，如霸凌、学习压力、家庭问题等，积极沟通并提供疏导"
			],
			"三、加强营养": [
				"了解孩子的消化和吸收情况，建议进行食物不耐受检查、肠道微生态检查和幽门螺杆菌检查",
				"设定合理的体重目标，避免采用极端的节食或快速减肥方法",
				"以体重管理为目标，限制高糖、高脂肪和高盐食物的摄入，多吃牛肉、虾、鱼、紫菜、蛋类、奶制品、坚果、燕麦、核桃、豌豆、扁豆等高蛋白质和高钙食物，同时增加新鲜水果和蔬菜的摄入，确保膳食纤维的充足",
				"鼓励孩子多喝水，保持水分摄入，限制含糖饮料和碳酸饮料，避免养成吃零食的习惯，按时吃早餐、午餐和晚餐，避免长时间不吃或暴饮暴食"
			],
			"四、运动锻炼": [
				"以体重管理为目标，确保每天至少40分钟的运动时间",
				"从轻松的活动开始，如散步或简单的家务，然后逐渐增加运动强度和持续时间",
				"多参与低冲击性运动，如游泳、骑自行车、慢跑、跳舞、体操、踢毽子、瑜伽、乒乓球、羽毛球等",
				"根据孩子的兴趣和体质，制定包含有氧、力量和灵活性训练的综合运动计划",
				"起床后做简单的伸展运动，如手臂上举伸展、腿部伸展、躯干扭转等，每天坚持5分钟",
				"建议做tabata锻炼：垫脚尖跳（30秒）、开合跳（30秒）、挺身跳（30秒）、开合左右交叉跳（30秒）、前后跳（30秒）、前后交叉跳（30秒）、左右跳（30秒）、深蹲跳（30秒），一组4分钟，每天1-3组。"
			],
			"五、生活习惯": [
				"限制晚上使用电子设备的时间，有助于孩子更好地入睡",
				"限制久坐，包括看电视、玩电子游戏和使用电脑的时间",
				"建立规律的作息时间，包括定时的起床、吃饭和睡觉",
				"晚上10点前上床睡觉，确保每天睡眠时间超过8小时",
				"重视孩子的睡眠质量，关注入睡困难、易惊醒、流口水等问题，及时进行调整",
				"确保室内环境的安全，避免甲醛、VOC超标以及噪声污染"
			]
		}
	}
}